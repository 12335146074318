"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcedureFieldType = void 0;
var ProcedureFieldType;
(function (ProcedureFieldType) {
    ProcedureFieldType["TEXT"] = "TEXT";
    ProcedureFieldType["CELLPHONE"] = "CELLPHONE";
    ProcedureFieldType["DATE"] = "DATE";
    ProcedureFieldType["SELECT"] = "SELECT";
    ProcedureFieldType["MULTIPLESELECT"] = "MULTIPLESELECT";
    ProcedureFieldType["CHECKBOX"] = "CHECKBOX";
    ProcedureFieldType["YESNO"] = "YESNO";
})(ProcedureFieldType = exports.ProcedureFieldType || (exports.ProcedureFieldType = {}));
