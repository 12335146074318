import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  RegisterProcedure,
  RegisterProcedureParams,
  ValidationError,
  extractValidationErrors,
  travelRequestForm,
  t,
  ProcedureType,
  GetAddressByCEP,
  getPatientFields,
  getProcedureFields,
  TravelGroup,
  Aid,
  GetFollowupTravelGroup,
  GetProgramFollowupTravels,
  getCitiesByState,
  TreatmentLocations,
  GetTravelGroupTreatmentLocations,
  ProcedureFieldType,
} from '@psp/common';
import { toast } from 'react-toastify';

import { useLoading } from '../../../contexts/loading.context';
import Input from '../../Input';
import Button from '../../Button';
import DynamicProcedreField from '../../DynamicProcedureField';
import DynamicAidFieldTemplate from '../../DynamicAidField';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import { useAuth } from '../../../contexts/auth.context';
import Select from '../../Select';
import DocumentInput from '../../DocumentInput';
import TravelSelect, { TravelSelectValues } from '../../TravelSelect';
import DateInput from '../../DateInput';
import MaskedPhoneInput from '../../MaskedPhoneInput';
import StateSelect from '../../StateSelect';
import TreatmentLocationsSelect, { TreatmentLocationsSelectValues } from '../../TreatmentLocationsSelect';

export type TravelRequestProps = {
  getAddressByCEP: GetAddressByCEP;
  registerProcedure: RegisterProcedure;
  getFollowupTravelGroup: GetFollowupTravelGroup;
  getProgramFollowupTravels: GetProgramFollowupTravels;
  getTravelGroupTreatmentLocations: GetTravelGroupTreatmentLocations;
};

type TravelRequestState = {
  travelGroupId: string,
  travelGroup: TravelGroup,
  treatmentLocations: TreatmentLocations[],
  aids?: Aid[];
  patientFieldConfig?: any[];
  procedureFieldConfig?: any[];
  allCitiesBySelectedState: any[];
  needRegister: boolean;
};

const defaultState: TravelRequestState = {
  travelGroupId: '',
  travelGroup: {} as TravelGroup,
  treatmentLocations: [],
  allCitiesBySelectedState: [],
  needRegister: false,
};

export default function TravelRequest({
  getAddressByCEP,
  registerProcedure,
  getTravelGroupTreatmentLocations,
  ...dispatchers
}: TravelRequestProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const patientStateRef: any = useRef(null);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();
  const { user } = useAuth();
  const [state, setState] = useState<TravelRequestState>(defaultState);

  const setErrorMensages = (data: any, extractedErrors: any = {}): boolean => {
    let isValid = true;
    const validationErrors = {} as any;

    state.patientFieldConfig?.forEach((field) => {
      if (
        field.visible
        && field.required
        && (data.patient[field.field] === undefined
          || data.patient[field.field] === null
          || data.patient[field.field] === ''
          || data.patient[field.field] === false)
      ) {
        validationErrors[`patient.${field.field}`] = t('validation.requiredField');
        isValid = false;
      }
    });

    state.procedureFieldConfig?.forEach((field) => {
      if (
        field.active
        && field.viewType === 'required'
        && (!data.procedureFieldsAnswers
          || data.procedureFieldsAnswers[field.name] === undefined
          || data.procedureFieldsAnswers[field.name] === ''
          || data.procedureFieldsAnswers[field.name] === null
          || data.procedureFieldsAnswers[field.name] === false
          || data.procedureFieldsAnswers[field.name].length === 0)
      ) {
        validationErrors[`procedureFieldsAnswers.${field.name}`] = t('validation.requiredField');
        isValid = false;
      }

      if (
        field.min !== null
        && field.min !== ''
        && data.procedureFieldsAnswers
        && data.procedureFieldsAnswers[field.name] !== null
        && data.procedureFieldsAnswers[field.name].length < field.min
      ) {
        const length = field.min;
        validationErrors[`procedureFieldsAnswers.${field.name}`] = t('validation.minLength', {
          length,
        });
        isValid = false;
      }

      if (
        field.requiredAnswers !== null
        && field.requiredAnswers !== ''
        && data.procedureFieldsAnswers
        && data.procedureFieldsAnswers[field.name] !== null
        && data.procedureFieldsAnswers[field.name].toString() !== field.requiredAnswers
      ) {
        let answer = field.requiredAnswers;

        if (field.requiredAnswers === 'true') {
          answer = 'Sim';
        }
        if (field.requiredAnswers === 'false') {
          answer = 'Não';
        }

        validationErrors[`procedureFieldsAnswers.${field.name}`] = t(
          'validation.requiredSelect',
        );
        isValid = false;
      }
    });

    formRef.current.setErrors({ ...validationErrors, ...extractedErrors });
    return isValid;
  };

  const handleCEPChange = (value: string) => {
    let cep = value;
    cep = cep.replace(/\D/g, '');

    if (cep.length > 7) {
      cep = cep.substring(0, 8);

      (async () => {
        showLoading();
        try {
          const addressByCEP = await getAddressByCEP.execute({
            cep,
          });

          if (!addressByCEP.erro) {
            formRef.current.setData({
              ...formRef.current.getData(),
              treatmentLocation: {
                ...formRef.current.getData().treatmentLocation,
                cep: addressByCEP.cep,
                placeDescription: addressByCEP.logradouro,
                complement: addressByCEP.complemento,
                neighborhood: addressByCEP.bairro,
                city: addressByCEP.localidade,
                state: addressByCEP.uf,
              },
            });
          } else {
            formRef.current.setData({
              ...formRef.current.getData(),
              treatmentLocations: {
                cep,
                placeDescription: '',
                number: '',
                complement: '',
                neighborhood: '',
                city: '',
                state: '',
              },
            });
          }
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    } else if (cep.length > 5) {
      cep = `${cep.slice(0, 5)}-${cep.slice(5)}`;

      formRef.current.setData({
        ...formRef.current.getData(),
        treatmentLocations: {
          ...formRef.current.getData().treatmentLocations,
          cep,
        },
      });
    }
  };

  const handleSubmit = useCallback(
    (data: any): void => {
      const itemIds = state.aids?.filter(
        (aid) => aid.aidFields.every((field) => data.aidFields[field.id] === true),
      )
        .map((aid) => aid.id) || [];

      if (itemIds.length < 1) {
        toast.info(t('err.unselectedTravelOption'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
        return;
      }

      showLoading();
      formRef.current.setErrors({});
      (async () => {
        try {
          const req = ((await travelRequestForm.validate(
            {
              ...data,
              type: ProcedureType.TRAVEL,
              programId: (program || {}).id,
              groupId: state.travelGroupId,
              itemIds,
            },
            {
              abortEarly: false,
              context: {
                needTreatmentLocationAddress: !!(data.treatmentLocationId === 'OTHER'),
              },
            },
          )) as unknown) as RegisterProcedureParams;

          if (setErrorMensages(data)) {
            await registerProcedure.execute({
              ...req,
              treatmentLocationId: data.treatmentLocationId === 'OTHER' ? '' : data.treatmentLocationId,
            });
            toast.success(t('msg.successToRequestTravel'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
            formRef.current.reset();
            setState(defaultState);
          }
        } catch (err: any) {
          console.log(err);
          if (err instanceof ValidationError) {
            setErrorMensages(data, extractValidationErrors(err));
          }

          if (err.message === 'eligibilityRejectedTravel') {
            toast.info(t('err.eligibilityRejectedUnder18'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            let errors: any = {};
            if (err instanceof ValidationError) {
              errors = extractValidationErrors(err);
              formRef.current.setErrors(errors);
            } else if (err.name === 'BadRequestError') {
              toast.info(err.message, {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            } else {
              toast.info(t('err.failedToRequestTravel'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [state, program],
  );

  const handleFolloupChange = useCallback(
    (values: TravelSelectValues) => {
      if (!values.travelGroupId) return;
      showLoading();
      (async () => {
        try {
          const patientFields = await getPatientFields.execute(
            { groupId: values.travelGroupId },
          );
          const procedureFields = await getProcedureFields.execute(
            { groupId: values.travelGroupId },
          );
          const treatmentLocations = await getTravelGroupTreatmentLocations.execute(
            { travelGroupId: values.travelGroupId },
          );
          setState({
            ...state,
            travelGroupId: values.travelGroupId,
            travelGroup: values.travelGroup,
            patientFieldConfig: patientFields,
            procedureFieldConfig: procedureFields,
            aids: values.travelGroup?.aids,
            treatmentLocations,
          });
        } catch (err) {
          formRef.current.reset();
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [state, program, user],
  );

  const handleTreatmentLocationsChange = useCallback(
    (values: TreatmentLocationsSelectValues): void => {
      if (!values) return;
      showLoading();
      try {
        setState({
          ...state,
          needRegister: values.needRegister,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    },
    [state],
  );

  const handleStateChange = async (value: string) => {
    showLoading();
    (async () => {
      try {
        const allCitiesBySelectedState = await getCitiesByState.execute({ state: value });

        setState({ ...state,
          allCitiesBySelectedState,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
        if (patientStateRef.current) {
          patientStateRef.current.focus();
        }
      }
    })();
  };

  const buildPatientFields = useCallback(() => {
    if (!state.patientFieldConfig) return <></>;
    const fields = [];
    const fieldMap = state.patientFieldConfig
      .filter((x) => x.visible)
      .reduce((p, c) => ({ ...p, [c.field]: c }), {});
    if (fieldMap.birthDate && fieldMap.birthDate.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <DateInput
            name="patient.birthDate"
            inputVariant="outlined"
            label={t('birthDate')}
            optional={!fieldMap.birthDate.required}
            format="dd/MM/yyyy"
            className={classes.input}
            disabled={isLoading}
          />
        </Grid>,
      );
    }
    if (fieldMap.rg && fieldMap.rg.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.rg"
            variant="outlined"
            label={t('rg')}
            optional={!fieldMap.rg.required}
            className={classes.input}
            disabled={isLoading}
            autoComplete="off"
          />
        </Grid>,
      );
    }
    fields.push(
      <Grid item xs={12} sm={4} lg={3} xl={2}>
        <Input
          name="patient.phone"
          variant="outlined"
          label={t('contactCellphone')}
          optional={!fieldMap.phone.required}
          className={classes.input}
          disabled={isLoading}
          autoComplete="off"
          InputProps={{
            inputComponent: MaskedPhoneInput as any,
          }}
        />
      </Grid>,
    );
    if (fieldMap.cep && fieldMap.cep.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.cep"
            variant="outlined"
            label={t('postalCode')}
            optional={!fieldMap.cep.required}
            className={classes.input}
            disabled={isLoading}
            autoComplete="off"
          />
        </Grid>,
      );
    }
    if (fieldMap.initials && fieldMap.initials.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.initials"
            variant="outlined"
            optional={!fieldMap.initials.required}
            label={t('nameInitials')}
            className={classes.input}
            disabled={isLoading}
            autoComplete="off"
          />
        </Grid>,
      );
    }
    if (fieldMap.caregiverName && fieldMap.caregiverName.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.caregiverName"
            variant="outlined"
            optional={!fieldMap.caregiverName.required}
            label={t('caregiverName')}
            className={classes.input}
            disabled={isLoading}
            autoComplete="off"
          />
        </Grid>,
      );
    }
    if (fieldMap.email && fieldMap.email.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.email"
            variant="outlined"
            optional={!fieldMap.email.required}
            label={t('email')}
            className={classes.input}
            disabled={isLoading}
            autoComplete="off"
          />
        </Grid>,
      );
    }
    if (fieldMap.state && fieldMap.state.visible) {
      fields.push(
        <Grid item xs={12} sm="auto">
          <StateSelect
            name="patient.state"
            variant="outlined"
            optional={!fieldMap.state.required}
            label={t('uf')}
            fullWidth
            displayEmpty
            className={classes.input}
            disabled={isLoading}
            onSelectedValueChange={handleStateChange}
          />
        </Grid>,
      );
    }
    if (fieldMap.city && fieldMap.city.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Select
            name="patient.city"
            label={t('city')}
            variant="outlined"
            optional={!fieldMap.city.required}
            fullWidth
            disabled={state?.allCitiesBySelectedState?.length === 0 || isLoading}
            inputRef={patientStateRef}
          >
            {state?.allCitiesBySelectedState?.map((p) => (
              <MenuItem key={p.id} value={p.city}>
                {p.city}
              </MenuItem>
            ))}
          </Select>
        </Grid>,
      );
    }

    return fields;
  }, [state.patientFieldConfig, isLoading]);

  const buildProcedureField = (field: any): JSX.Element => {
    if (field.active && !field.showAtBottom) {
      return (
        <>
          <Grid key={field.id} item xs={12} sm={4} lg={3} xl={2}>
            <DynamicProcedreField
              name={`procedureFieldsAnswers.${field.name}`}
              max={field.max}
              mask={field.mask}
              label={field.label ?? field.name}
              optional={field.viewType !== 'required'}
              fieldType={field.fieldType}
              options={field.options}
              active
            />
          </Grid>
        </>
      );
    }

    return <></>;
  };

  const buildAidFields = useCallback(() => {
    if (!state.travelGroup?.aids?.length) return <></>;

    const fields = state.travelGroup?.aids?.map((aid) => (
      <Grid key={aid?.type} item xs={12} sm>
        {aid.aidFields.map((aidField) => (
          <DynamicAidFieldTemplate
            key={aidField.id}
            name={`aidFields.${aidField.id}`}
            label={aidField.label}
            fieldType={aidField.fieldType}
            active
          />
        ))}
      </Grid>
    ));

    return fields;
  }, [state.travelGroup, isLoading]);

  const buildBottomProcedureFields = useCallback(() => {
    if (!state.procedureFieldConfig) return <></>;
    const fieldGroups = state.procedureFieldConfig
      .filter((x) => x.active && x.showAtBottom)
      .reduce((p, c) => ({
        ...p,
        [c.group ?? 0]: [...(p[c.group ?? 0] ?? []), c],
      }), {});

    const fields = Object.keys(fieldGroups)
      .sort((a, b) => a.localeCompare(b))
      .map((k) => (
        <Grid key={k} item xs={12} sm>
          {
          fieldGroups[k]
            .sort((a: any, b: any) => a.order - b.order)
            .map((x: any) => (
              <DynamicProcedreField
                key={x.id}
                name={`procedureFieldsAnswers.${x.name}`}
                max={x.max}
                mask={x.mask}
                label={x.name}
                fieldType={x.fieldType}
                options={x.options}
                active
              />
            ))
          }
        </Grid>
      ));

    return fields;
  }, [state.procedureFieldConfig, isLoading]);

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <PaperTitle title={t('selectTravel')} />
        <PaperContent>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ patient: { acceptSms: true, acceptEmail: true } }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm={8} lg={6} xl={4}>
                <TravelSelect
                  {...dispatchers}
                  onSelectedValueChange={handleFolloupChange}
                  formRef={formRef}
                />
              </Grid>
              {state.travelGroupId && (
                <Grid item>
                  <Grid container spacing={2} direction="column" className={classes.nowrap}>
                    <Grid item>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={12} sm={4} lg={3} xl={2}>
                          <Input
                            name="patient.name"
                            variant="outlined"
                            label={t('patientFullName')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3} xl={2}>
                          <Input
                            name="patient.cpf"
                            variant="outlined"
                            label={t('patientCpf')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="off"
                            InputProps={{
                              inputComponent: DocumentInput as any,
                            }}
                          />
                        </Grid>
                        {buildPatientFields()}
                        {state.procedureFieldConfig
                          && state.procedureFieldConfig
                            .sort((a: any, b: any) => a.order - b.order)
                            .map((x) => buildProcedureField(x))}
                      </Grid>
                      <Grid item>
                        <Grid container spacing={4} direction="row">
                          <Grid item xs={12} md={6}>
                            {buildAidFields()}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={2} direction="row">
                          <Grid item xs={12} sm={12} lg={12}>
                            <Typography variant="h6">
                              <strong>{state.travelGroup.treatmentLocationLabel ?? t('selectTreatmentLocation') }</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={4} xl={3}>
                            <TreatmentLocationsSelect
                              name="treatmentLocationId"
                              onSelectedValueChange={handleTreatmentLocationsChange}
                              treatmentLocations={state.treatmentLocations}
                              label={state.travelGroup.treatmentLocationLabel ?? t('selectTreatmentLocation')}
                              showOutherOption={
                                state.travelGroup.showOtherOptionForTreatmentLocation
                              }
                            />
                          </Grid>
                          {state.needRegister && (
                            <>
                              <Grid item xs={12} sm={3} lg={3}>
                                <Input
                                  name="treatmentLocation.name"
                                  variant="outlined"
                                  label={t('name')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                />
                              </Grid>
                              <Grid item xs={12} sm={3} lg={3}>
                                <Input
                                  name="treatmentLocation.cep"
                                  variant="outlined"
                                  label={t('cep')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                  maxLength={9}
                                  onValueChange={handleCEPChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={9} lg={9}>
                                <Input
                                  name="treatmentLocation.placeDescription"
                                  variant="outlined"
                                  label={t('placeDescription')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                  maxLength={150}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3} lg={3}>
                                <Input
                                  name="treatmentLocation.number"
                                  variant="outlined"
                                  label={t('number')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                  maxLength={20}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} lg={4}>
                                <Input
                                  name="treatmentLocation.complement"
                                  variant="outlined"
                                  label={t('complement')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                  maxLength={50}
                                  optional
                                />
                              </Grid>
                              <Grid item xs={12} sm={5} lg={5}>
                                <Input
                                  name="treatmentLocation.neighborhood"
                                  variant="outlined"
                                  label={t('neighborhood')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                  maxLength={50}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} lg={4}>
                                <Input
                                  name="treatmentLocation.city"
                                  variant="outlined"
                                  label={t('city')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                  maxLength={50}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} lg={4}>
                                <Input
                                  name="treatmentLocation.state"
                                  variant="outlined"
                                  label={t('state')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                  maxLength={50}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={4} direction="row" alignItems="flex-end">
                          <Grid item xs={12} sm>
                            <Grid container direction="column" spacing={3} xs={12} sm>
                              {buildBottomProcedureFields()}
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Button type="submit" variant="contained" color="primary">
                              Solicitar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
