"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcedureType = void 0;
var ProcedureType;
(function (ProcedureType) {
    ProcedureType["FIRST_MEDICINE"] = "FIRST_MEDICINE";
    ProcedureType["CLINICAL_EXAMINATION"] = "CLINICAL_EXAMINATION";
    ProcedureType["PATIENT_PROCEDURE"] = "PATIENT_PROCEDURE";
    ProcedureType["SALESFORCE_VISIT"] = "SALESFORCE_VISIT";
    ProcedureType["SUPPLY"] = "SUPPLY";
    ProcedureType["DOCTOR_BENEFITS"] = "DOCTOR_BENEFITS";
    ProcedureType["AID"] = "AID";
    ProcedureType["TRAVEL"] = "TRAVEL";
    ProcedureType["PATIENTS"] = "PATIENTS";
    ProcedureType["DISCOUNTS"] = "DISCOUNTS";
})(ProcedureType = exports.ProcedureType || (exports.ProcedureType = {}));
