import React from 'react';
import {
  getInventoryAmount,
  getProgramProducts,
  getProductPatientProcedures,
  registerProcedure,
  getPatientFields,
  getProcedureFields,
} from '@psp/common';
import PatientProcedureRequest, {
  PatientProcedureRequestProps,
} from '../../components/PatientProcedure/Request';

const dispatchers: Pick<
  PatientProcedureRequestProps,
  | 'getInventoryAmount'
  | 'getProgramProducts'
  | 'getProductPatientProcedures'
  | 'registerProcedure'
  | 'getPatientFields'
  | 'getProcedureFields'
> = {
  getInventoryAmount,
  getProgramProducts,
  getProductPatientProcedures,
  registerProcedure,
  getPatientFields,
  getProcedureFields,
};

export default function PatientProcedureRequestComposer(): JSX.Element {
  return <PatientProcedureRequest {...dispatchers} />;
}
