import React, { createContext, useContext, useEffect, useState, ReactElement } from 'react';
import { SvgIconComponent, DvrOutlined, PostAddOutlined } from '@material-ui/icons';
import {
  LayersTripleOutline,
  CubeOutline,
  Account,
  Stethoscope,
  CubeSend,
  BallotOutline,
  Pill,
  HeartOutline,
  HeartPulse,
  ViewGridOutline,
  AccountArrowRightOutline,
  TruckDeliveryOutline,
  Bookshelf,
  MedicalBag,
  AppleKeyboardCommand,
  ArrowDecision,
  HospitalBox,
  Factory,
  Airplane,
  AirplaneTakeoff,
  AccountGroup,
  FileDocumentMultiple,
  NoteTextOutline,
  HumanEdit,
  Newspaper,
  Sale,
} from 'mdi-material-ui';
import { RouteComponentProps } from 'react-router-dom';
import { ProgramModulesEnum, UserType, t } from '@psp/common';

import {
  DASHBOARD_ROUTE,
  DOCTORS_ROUTE,
  DOCTOR_DETAIL_ROUTE,
  EXAM_REQUEST_ROUTE,
  INVENTORY_DISTRIBUTION_ROUTE,
  INVENTORY_ROUTE,
  MEDICATION_RELEASE_ROUTE,
  MEDICINE_LEAFLETS_ROUTE,
  PATIENT_PROCEDURE_REQUEST_ROUTE,
  PROCEDURES_EXAMS_DETAIL_ROUTE,
  PROCEDURES_EXAMS_ROUTE,
  PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
  PROCEDURES_FIRST_MEDICINE_ROUTE,
  PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
  PROCEDURES_PATIENT_PROCEDURES_ROUTE,
  PROCEDURES_SALESFORCE_VISITS_ROUTE,
  PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE,
  PROCEDURES_ROUTE,
  PROCEDURE_DETAIL_ROUTE,
  USERS_ROUTE,
  USER_EDIT_ROUTE,
  USER_DETAILS_ROUTE,
  USER_USER_GROUPS_ROUTE,
  USER_USER_GROUPS_HISTORY_ROUTE,
  USER_REGISTER_ROUTE,
  SUPPLY_REQUEST_ROUTE,
  USER_GROUPS_ROUTE,
  USER_GROUP_PERMISSIONS_ROUTE,
  LIBRARY_ROUTE,
  PRODUCTS_ROUTE,
  PRODUCT_REGISTER_ROUTE,
  PRODUCT_EDIT_ROUTE,
  PROGRAMS_ROUTE,
  PROGRAM_REGISTER_ROUTE,
  PROGRAM_EDIT_ROUTE,
  FOLLOWUPS_ROUTE,
  FOLLOWUP_REGISTER_ROUTE,
  FOLLOWUP_EDIT_ROUTE,
  PRESENTATION_REGISTER_ROUTE,
  PRESENTATION_EDIT_ROUTE,
  PRESENTATIONS_ROUTE,
  CLIENTS_ROUTES,
  CLIENT_EDIT_ROUTE,
  CLIENT_REGISTER_ROUTE,
  SUPPLY_REQUESTS_ROUTE,
  SUPPLY_REQUESTS_DETAIL_ROUTE,
  USER_EMAIL_CHANGE_ROUTE,
  TRAVEL_REQUEST_ROUTE,
  TRAVEL_REQUESTS_ROUTE,
  TRAVEL_REQUESTS_DETAIL_ROUTE,
  PATIENTS_ROUTE,
  PATIENT_DETAIL_ROUTE,
  MEDICATION_ADMINISTRATION,
  CONTENTS_ROUTE,
  DISCOUNTS_ROUTE,
} from '../constants';
import DashboardPage from '../pages/dashboard';
import DoctorDetailsPage from '../pages/doctors/details';
import DoctorListPage from '../pages/doctors/list';
import ExamRequestPage from '../pages/exams/request';
import InventoryDistributionPage from '../pages/inventory/distribution';
import InventoryPage from '../pages/inventory/list';
import MedicationReleasePage from '../pages/medications/release';
import PatientProcedureRequestPage from '../pages/patient-procedures/request';
import ProcedureDetailsPage from '../pages/procedures/details';
import ProcedureListPage from '../pages/procedures/list';
import UserListPage from '../pages/users/list';
import UserRegisterPage from '../pages/users/register';
import { useAuth } from './auth.context';
import { useProgram } from './program.context';
import FirstMedicineProcedureListPage from '../pages/procedures/medications/list';
import ClinicalExaminationProcedureListPage from '../pages/procedures/exams/list';
import PatientProcedureProcedureListPage from '../pages/procedures/patient-procedures/list';
import SalesforceVisitProcedureListPage from '../pages/procedures/visits/list';
import SupplyRequestPage from '../pages/supply/request';
import UserGroupsPage from '../pages/user-groups';
import UserGroupUsersPage from '../pages/user-groups/users';
import UserGroupHistoryPage from '../pages/user-groups/history';
import UserGroupPermissionsPage from '../pages/user-groups/permissions';
import LibraryPage from '../pages/library/list';
import ProductListPage from '../pages/products/list';
import ProductRegisterPage from '../pages/products/register';
import ProgramListPage from '../pages/programs/list';
import ProgramRegisterPage from '../pages/programs/register';
import FollowupListPage from '../pages/followups/list';
import FollowupRegisterPage from '../pages/followups/register';
import PresentationListPage from '../pages/presentations/list';
import PresentationRegisterPage from '../pages/presentations/register';
import ClientListPage from '../pages/clients/list';
import ClientRegisterPage from '../pages/clients/register';
import SupplyRequestsListPage from '../pages/supply/request/list';
import UserChangeEmailPage from '../pages/users/change-email';
import TravelRequestPage from '../pages/travel/request';
import TravelRequestsListPage from '../pages/travel/request/list';
import PatientsListPage from '../pages/patients/list';
import MedicationAdministrationListPage from '../pages/medication-administration/list';
import PatientDetailsPage from '../pages/patients/details';
import MedicineLeafletsPage from '../pages/medicine-leaflets';
import UserDetailsPage from '../pages/users/details';
import ContentsPage from '../pages/contents';
import DiscountsPage from '../pages/discounts';

type Route = {
  icon?: SvgIconComponent;
  label?: string;
  route: string;
  sidebar?: boolean;
  quickAccess?: boolean;
  dashboard?: boolean;
  exact: boolean;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

export type RoutesContextState = {
  default: string;
  routes: Route[];
};

export type RoutesContextProps = {
  children: ReactElement;
};

const defaultState: RoutesContextState = {
  default: '',
  routes: [],
};

const routes: {
  [key: string]: Route;
} = {
  [DASHBOARD_ROUTE]: {
    icon: ViewGridOutline,
    label: t(`navigation.${DASHBOARD_ROUTE}`),
    route: DASHBOARD_ROUTE,
    sidebar: true,
    component: DashboardPage,
    exact: false,
    dashboard: true,
  },
  [MEDICATION_RELEASE_ROUTE]: {
    icon: LayersTripleOutline,
    label: t(`navigation.${MEDICATION_RELEASE_ROUTE}`),
    route: MEDICATION_RELEASE_ROUTE,
    sidebar: true,
    component: MedicationReleasePage,
    exact: false,
  },
  [MEDICINE_LEAFLETS_ROUTE]: {
    icon: NoteTextOutline,
    label: t(`navigation.${MEDICINE_LEAFLETS_ROUTE}`),
    route: MEDICINE_LEAFLETS_ROUTE,
    sidebar: true,
    component: MedicineLeafletsPage,
    exact: false,
  },
  [EXAM_REQUEST_ROUTE]: {
    icon: PostAddOutlined,
    label: t(`navigation.${EXAM_REQUEST_ROUTE}`),
    route: EXAM_REQUEST_ROUTE,
    sidebar: true,
    component: ExamRequestPage,
    exact: false,
  },
  [PATIENT_PROCEDURE_REQUEST_ROUTE]: {
    icon: DvrOutlined,
    label: t(`navigation.${PATIENT_PROCEDURE_REQUEST_ROUTE}`),
    route: PATIENT_PROCEDURE_REQUEST_ROUTE,
    sidebar: true,
    component: PatientProcedureRequestPage,
    exact: false,
  },
  [INVENTORY_ROUTE]: {
    icon: CubeOutline,
    label: t(`navigation.${INVENTORY_ROUTE}`),
    route: INVENTORY_ROUTE,
    sidebar: true,
    component: InventoryPage,
    exact: true,
  },
  [DOCTORS_ROUTE]: {
    icon: Stethoscope,
    label: t(`navigation.${DOCTORS_ROUTE}`),
    route: DOCTORS_ROUTE,
    sidebar: true,
    component: DoctorListPage,
    exact: true,
  },
  [DOCTOR_DETAIL_ROUTE]: {
    route: DOCTOR_DETAIL_ROUTE,
    component: DoctorDetailsPage,
    exact: true,
  },
  [INVENTORY_DISTRIBUTION_ROUTE]: {
    icon: CubeSend,
    label: t(`navigation.${INVENTORY_DISTRIBUTION_ROUTE}`),
    route: INVENTORY_DISTRIBUTION_ROUTE,
    sidebar: true,
    component: InventoryDistributionPage,
    exact: true,
  },
  [PROCEDURES_ROUTE]: {
    icon: BallotOutline,
    label: t(`navigation.${PROCEDURES_ROUTE}`),
    route: PROCEDURES_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: ProcedureListPage,
    exact: true,
  },
  [PROCEDURES_EXAMS_ROUTE]: {
    icon: HeartOutline,
    label: t(`navigation.${PROCEDURES_EXAMS_ROUTE}`),
    route: PROCEDURES_EXAMS_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: ClinicalExaminationProcedureListPage,
    exact: true,
  },
  [PROCEDURES_FIRST_MEDICINE_ROUTE]: {
    icon: Pill,
    label: t(`navigation.${PROCEDURES_FIRST_MEDICINE_ROUTE}`),
    route: PROCEDURES_FIRST_MEDICINE_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: FirstMedicineProcedureListPage,
    exact: true,
  },
  [PROCEDURES_PATIENT_PROCEDURES_ROUTE]: {
    icon: HeartPulse,
    label: t(`navigation.${PROCEDURES_PATIENT_PROCEDURES_ROUTE}`),
    route: PROCEDURES_PATIENT_PROCEDURES_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: PatientProcedureProcedureListPage,
    exact: true,
  },
  [PROCEDURES_SALESFORCE_VISITS_ROUTE]: {
    icon: AccountArrowRightOutline,
    label: t(`navigation.${PROCEDURES_SALESFORCE_VISITS_ROUTE}`),
    route: PROCEDURES_SALESFORCE_VISITS_ROUTE,
    sidebar: true,
    quickAccess: false,
    component: SalesforceVisitProcedureListPage,
    exact: true,
  },
  [SUPPLY_REQUESTS_ROUTE]: {
    icon: MedicalBag,
    label: t(`navigation.${SUPPLY_REQUESTS_ROUTE}`),
    route: SUPPLY_REQUESTS_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: SupplyRequestsListPage,
    exact: true,
  },
  [PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE]: {
    route: PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    component: ProcedureDetailsPage,
    exact: true,
  },
  [PROCEDURES_EXAMS_DETAIL_ROUTE]: {
    route: PROCEDURES_EXAMS_DETAIL_ROUTE,
    component: ProcedureDetailsPage,
    exact: true,
  },
  [PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE]: {
    route: PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
    component: ProcedureDetailsPage,
    exact: true,
  },
  [PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE]: {
    route: PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE,
    component: ProcedureDetailsPage,
    exact: true,
  },
  [PROCEDURE_DETAIL_ROUTE]: {
    route: PROCEDURE_DETAIL_ROUTE,
    component: ProcedureDetailsPage,
    exact: true,
  },
  [USERS_ROUTE]: {
    icon: Account,
    label: t(`navigation.${USERS_ROUTE}`),
    route: USERS_ROUTE,
    sidebar: true,
    component: UserListPage,
    exact: true,
  },
  [USER_REGISTER_ROUTE]: {
    route: USER_REGISTER_ROUTE,
    component: UserRegisterPage,
    exact: true,
  },
  [USER_EDIT_ROUTE]: {
    route: USER_EDIT_ROUTE,
    component: UserRegisterPage,
    exact: true,
  },
  [USER_DETAILS_ROUTE]: {
    icon: HumanEdit,
    label: t(`navigation.${USER_DETAILS_ROUTE}`),
    route: USER_DETAILS_ROUTE,
    sidebar: true,
    component: UserDetailsPage,
    exact: true,
  },
  [USER_USER_GROUPS_ROUTE]: {
    route: USER_USER_GROUPS_ROUTE,
    component: UserGroupUsersPage,
    exact: true,
  },
  [USER_USER_GROUPS_HISTORY_ROUTE]: {
    route: USER_USER_GROUPS_HISTORY_ROUTE,
    component: UserGroupHistoryPage,
    exact: true,
  },
  [USER_EMAIL_CHANGE_ROUTE]: {
    route: USER_EMAIL_CHANGE_ROUTE,
    component: UserChangeEmailPage,
    exact: true,
  },
  [USER_GROUPS_ROUTE]: {
    icon: Account,
    label: t(`navigation.${USER_GROUPS_ROUTE}`),
    route: USER_GROUPS_ROUTE,
    sidebar: true,
    component: UserGroupsPage,
    exact: true,
  },
  [USER_GROUP_PERMISSIONS_ROUTE]: {
    route: USER_GROUP_PERMISSIONS_ROUTE,
    component: UserGroupPermissionsPage,
    exact: false,
  },
  [SUPPLY_REQUEST_ROUTE]: {
    icon: TruckDeliveryOutline,
    label: t(`navigation.${SUPPLY_REQUEST_ROUTE}`),
    route: SUPPLY_REQUEST_ROUTE,
    sidebar: true,
    component: SupplyRequestPage,
    exact: false,
  },
  [SUPPLY_REQUESTS_DETAIL_ROUTE]: {
    route: SUPPLY_REQUESTS_DETAIL_ROUTE,
    component: ProcedureDetailsPage,
    exact: true,
  },
  [LIBRARY_ROUTE]: {
    icon: Bookshelf,
    label: t(`navigation.${LIBRARY_ROUTE}`),
    route: LIBRARY_ROUTE,
    sidebar: true,
    component: LibraryPage,
    exact: false,
  },
  [PRODUCTS_ROUTE]: {
    icon: MedicalBag,
    label: t(`navigation.${PRODUCTS_ROUTE}`),
    route: PRODUCTS_ROUTE,
    sidebar: true,
    component: ProductListPage,
    exact: true,
  },
  [PRODUCT_REGISTER_ROUTE]: {
    route: PRODUCT_REGISTER_ROUTE,
    component: ProductRegisterPage,
    exact: true,
  },
  [PRODUCT_EDIT_ROUTE]: {
    route: PRODUCT_EDIT_ROUTE,
    component: ProductRegisterPage,
    exact: true,
  },
  [PROGRAMS_ROUTE]: {
    icon: AppleKeyboardCommand,
    label: t(`navigation.${PROGRAMS_ROUTE}`),
    route: PROGRAMS_ROUTE,
    sidebar: true,
    component: ProgramListPage,
    exact: true,
  },
  [PROGRAM_REGISTER_ROUTE]: {
    route: PROGRAM_REGISTER_ROUTE,
    component: ProgramRegisterPage,
    exact: true,
  },
  [PROGRAM_EDIT_ROUTE]: {
    route: PROGRAM_EDIT_ROUTE,
    component: ProgramRegisterPage,
    exact: true,
  },
  [FOLLOWUPS_ROUTE]: {
    icon: ArrowDecision,
    label: t(`navigation.${FOLLOWUPS_ROUTE}`),
    route: FOLLOWUPS_ROUTE,
    sidebar: true,
    component: FollowupListPage,
    exact: true,
  },
  [FOLLOWUP_REGISTER_ROUTE]: {
    route: FOLLOWUP_REGISTER_ROUTE,
    component: FollowupRegisterPage,
    exact: true,
  },
  [FOLLOWUP_EDIT_ROUTE]: {
    route: FOLLOWUP_EDIT_ROUTE,
    component: FollowupRegisterPage,
    exact: true,
  },
  [PRESENTATIONS_ROUTE]: {
    icon: HospitalBox,
    label: t(`navigation.${PRESENTATIONS_ROUTE}`),
    route: PRESENTATIONS_ROUTE,
    sidebar: true,
    component: PresentationListPage,
    exact: true,
  },
  [PRESENTATION_REGISTER_ROUTE]: {
    route: PRESENTATION_REGISTER_ROUTE,
    component: PresentationRegisterPage,
    exact: true,
  },
  [PRESENTATION_EDIT_ROUTE]: {
    route: PRESENTATION_EDIT_ROUTE,
    component: PresentationRegisterPage,
    exact: true,
  },
  [CLIENTS_ROUTES]: {
    icon: Factory,
    label: t(`navigation.${CLIENTS_ROUTES}`),
    route: CLIENTS_ROUTES,
    sidebar: true,
    component: ClientListPage,
    exact: true,
  },
  [CLIENT_EDIT_ROUTE]: {
    route: CLIENT_EDIT_ROUTE,
    component: ClientRegisterPage,
    exact: true,
  },
  [CLIENT_REGISTER_ROUTE]: {
    route: CLIENT_REGISTER_ROUTE,
    component: ClientRegisterPage,
    exact: true,
  },
  [TRAVEL_REQUEST_ROUTE]: {
    icon: Airplane,
    label: t(`navigation.${TRAVEL_REQUEST_ROUTE}`),
    route: TRAVEL_REQUEST_ROUTE,
    sidebar: true,
    component: TravelRequestPage,
    exact: false,
  },
  [TRAVEL_REQUESTS_ROUTE]: {
    icon: AirplaneTakeoff,
    label: t(`navigation.${TRAVEL_REQUESTS_ROUTE}`),
    route: TRAVEL_REQUESTS_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: TravelRequestsListPage,
    exact: true,
  },
  [TRAVEL_REQUESTS_DETAIL_ROUTE]: {
    route: TRAVEL_REQUESTS_DETAIL_ROUTE,
    component: ProcedureDetailsPage,
    exact: true,
  },
  [PATIENTS_ROUTE]: {
    icon: AccountGroup,
    label: t(`navigation.${PATIENTS_ROUTE}`),
    route: PATIENTS_ROUTE,
    sidebar: true,
    quickAccess: true,
    component: PatientsListPage,
    exact: true,
  },
  [PATIENT_DETAIL_ROUTE]: {
    route: PATIENT_DETAIL_ROUTE,
    component: PatientDetailsPage,
    exact: true,
  },
  [MEDICATION_ADMINISTRATION]: {
    icon: FileDocumentMultiple,
    label: t(`navigation.${MEDICATION_ADMINISTRATION}`),
    route: MEDICATION_ADMINISTRATION,
    sidebar: true,
    quickAccess: true,
    component: MedicationAdministrationListPage,
    exact: true,
  },
  [CONTENTS_ROUTE]: {
    icon: Newspaper,
    label: t(`navigation.${CONTENTS_ROUTE}`),
    route: CONTENTS_ROUTE,
    sidebar: true,
    component: ContentsPage,
    exact: true,
  },

  [DISCOUNTS_ROUTE]: {
    icon: Sale,
    label: t(`navigation.${DISCOUNTS_ROUTE}`),
    route: DISCOUNTS_ROUTE,
    sidebar: true,
    component: DiscountsPage,
    exact: true,
  },
};

const roleRoutes = {
  [UserType.DOCTOR]: [
    DASHBOARD_ROUTE,
    MEDICATION_RELEASE_ROUTE,
    MEDICINE_LEAFLETS_ROUTE,
    EXAM_REQUEST_ROUTE,
    PATIENT_PROCEDURE_REQUEST_ROUTE,
    INVENTORY_ROUTE,
    PROCEDURES_FIRST_MEDICINE_ROUTE,
    PROCEDURES_EXAMS_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_ROUTE,
    PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    PROCEDURES_EXAMS_DETAIL_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    SUPPLY_REQUEST_ROUTE,
    SUPPLY_REQUESTS_ROUTE,
    SUPPLY_REQUESTS_DETAIL_ROUTE,
    TRAVEL_REQUEST_ROUTE,
    TRAVEL_REQUESTS_ROUTE,
    TRAVEL_REQUESTS_DETAIL_ROUTE,
    LIBRARY_ROUTE,
    USER_EMAIL_CHANGE_ROUTE,
    USER_DETAILS_ROUTE,
    PATIENTS_ROUTE,
    PATIENT_DETAIL_ROUTE,
    MEDICATION_ADMINISTRATION,
    CONTENTS_ROUTE,
    DISCOUNTS_ROUTE,
  ],
  [UserType.REPRESENTATIVE]: [
    DOCTORS_ROUTE,
    DOCTOR_DETAIL_ROUTE,
    INVENTORY_ROUTE,
    INVENTORY_DISTRIBUTION_ROUTE,
    PROCEDURES_FIRST_MEDICINE_ROUTE,
    PROCEDURES_EXAMS_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_ROUTE,
    PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    PROCEDURES_EXAMS_DETAIL_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    LIBRARY_ROUTE,
    SUPPLY_REQUESTS_ROUTE,
    SUPPLY_REQUESTS_DETAIL_ROUTE,
    TRAVEL_REQUESTS_ROUTE,
    TRAVEL_REQUESTS_DETAIL_ROUTE,
    USER_EMAIL_CHANGE_ROUTE,
  ],
  [UserType.DISTRICT_MANAGER]: [
    DOCTORS_ROUTE,
    DOCTOR_DETAIL_ROUTE,
    INVENTORY_ROUTE,
    INVENTORY_DISTRIBUTION_ROUTE,
    PROCEDURES_FIRST_MEDICINE_ROUTE,
    PROCEDURES_EXAMS_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_ROUTE,
    PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    PROCEDURES_EXAMS_DETAIL_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    LIBRARY_ROUTE,
    SUPPLY_REQUESTS_ROUTE,
    SUPPLY_REQUESTS_DETAIL_ROUTE,
    TRAVEL_REQUESTS_ROUTE,
    TRAVEL_REQUESTS_DETAIL_ROUTE,
    USER_EMAIL_CHANGE_ROUTE,
  ],
  [UserType.REGIONAL_MANAGER]: [
    DOCTORS_ROUTE,
    DOCTOR_DETAIL_ROUTE,
    INVENTORY_ROUTE,
    INVENTORY_DISTRIBUTION_ROUTE,
    PROCEDURES_FIRST_MEDICINE_ROUTE,
    PROCEDURES_EXAMS_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_ROUTE,
    PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    PROCEDURES_EXAMS_DETAIL_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    LIBRARY_ROUTE,
    SUPPLY_REQUESTS_ROUTE,
    SUPPLY_REQUESTS_DETAIL_ROUTE,
    TRAVEL_REQUESTS_ROUTE,
    TRAVEL_REQUESTS_DETAIL_ROUTE,
    USER_EMAIL_CHANGE_ROUTE,
  ],
  [UserType.ADMIN]: [
    MEDICINE_LEAFLETS_ROUTE,
    MEDICATION_RELEASE_ROUTE,
    DOCTORS_ROUTE,
    DOCTOR_DETAIL_ROUTE,
    INVENTORY_DISTRIBUTION_ROUTE,
    PROCEDURES_FIRST_MEDICINE_ROUTE,
    PROCEDURES_EXAMS_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_ROUTE,
    PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    PROCEDURES_EXAMS_DETAIL_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    USERS_ROUTE,
    USER_EDIT_ROUTE,
    USER_REGISTER_ROUTE,
    USER_GROUPS_ROUTE,
    USER_USER_GROUPS_ROUTE,
    USER_USER_GROUPS_HISTORY_ROUTE,
    USER_GROUP_PERMISSIONS_ROUTE,
    LIBRARY_ROUTE,
    SUPPLY_REQUESTS_ROUTE,
    SUPPLY_REQUESTS_DETAIL_ROUTE,
    TRAVEL_REQUESTS_ROUTE,
    TRAVEL_REQUESTS_DETAIL_ROUTE,
    CONTENTS_ROUTE,
    DISCOUNTS_ROUTE,
  ],
  [UserType.SYSADMIN]: [
    DOCTORS_ROUTE,
    DOCTOR_DETAIL_ROUTE,
    INVENTORY_DISTRIBUTION_ROUTE,
    PROCEDURES_FIRST_MEDICINE_ROUTE,
    PROCEDURES_EXAMS_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_ROUTE,
    PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    PROCEDURES_EXAMS_DETAIL_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    USERS_ROUTE,
    USER_EDIT_ROUTE,
    CLIENT_EDIT_ROUTE,
    USER_REGISTER_ROUTE,
    USER_GROUPS_ROUTE,
    USER_USER_GROUPS_ROUTE,
    USER_USER_GROUPS_HISTORY_ROUTE,
    USER_GROUP_PERMISSIONS_ROUTE,
    LIBRARY_ROUTE,
    PRODUCTS_ROUTE,
    PRODUCT_REGISTER_ROUTE,
    PRODUCT_EDIT_ROUTE,
    PROGRAMS_ROUTE,
    PROGRAM_REGISTER_ROUTE,
    PROGRAM_EDIT_ROUTE,
    FOLLOWUPS_ROUTE,
    FOLLOWUP_REGISTER_ROUTE,
    FOLLOWUP_EDIT_ROUTE,
    PRESENTATIONS_ROUTE,
    PRESENTATION_REGISTER_ROUTE,
    PRESENTATION_EDIT_ROUTE,
    CLIENTS_ROUTES,
    CLIENT_REGISTER_ROUTE,
    SUPPLY_REQUESTS_ROUTE,
    SUPPLY_REQUESTS_DETAIL_ROUTE,
    TRAVEL_REQUESTS_ROUTE,
    TRAVEL_REQUESTS_DETAIL_ROUTE,
    CONTENTS_ROUTE,
    DISCOUNTS_ROUTE,
  ],
};

const moduleRoutes = {
  base: [
    DASHBOARD_ROUTE,
    DOCTORS_ROUTE,
    DOCTOR_DETAIL_ROUTE,
    USERS_ROUTE,
    USER_REGISTER_ROUTE,
    USER_EDIT_ROUTE,
    CLIENT_EDIT_ROUTE,
    PRODUCTS_ROUTE,
    PRODUCT_REGISTER_ROUTE,
    PRODUCT_EDIT_ROUTE,
    PROGRAMS_ROUTE,
    PROGRAM_REGISTER_ROUTE,
    PROGRAM_EDIT_ROUTE,
    FOLLOWUPS_ROUTE,
    FOLLOWUP_REGISTER_ROUTE,
    FOLLOWUP_EDIT_ROUTE,
    PRESENTATIONS_ROUTE,
    PRESENTATION_REGISTER_ROUTE,
    PRESENTATION_EDIT_ROUTE,
    CLIENTS_ROUTES,
    CLIENT_REGISTER_ROUTE,
    USER_GROUPS_ROUTE,
    USER_USER_GROUPS_ROUTE,
    USER_USER_GROUPS_HISTORY_ROUTE,
    USER_GROUP_PERMISSIONS_ROUTE,
    USER_EMAIL_CHANGE_ROUTE,
  ],
  [ProgramModulesEnum.CLINICAL_EXAMINATION]: [
    INVENTORY_ROUTE,
    INVENTORY_DISTRIBUTION_ROUTE,
    PROCEDURES_EXAMS_ROUTE,
    PROCEDURES_EXAMS_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    EXAM_REQUEST_ROUTE,
  ],
  [ProgramModulesEnum.FIRST_MEDICINE]: [
    INVENTORY_ROUTE,
    INVENTORY_DISTRIBUTION_ROUTE,
    PROCEDURES_FIRST_MEDICINE_ROUTE,
    PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    MEDICATION_RELEASE_ROUTE,
    MEDICINE_LEAFLETS_ROUTE,
  ],
  [ProgramModulesEnum.PATIENT_PROCEDURE]: [
    INVENTORY_ROUTE,
    INVENTORY_DISTRIBUTION_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_ROUTE,
    PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    PATIENT_PROCEDURE_REQUEST_ROUTE,
  ],
  [ProgramModulesEnum.SALESFORCE_VISIT]: [
    PROCEDURES_SALESFORCE_VISITS_ROUTE,
    PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
  ],
  [ProgramModulesEnum.SUPPLY]: [
    SUPPLY_REQUEST_ROUTE,
    SUPPLY_REQUESTS_ROUTE,
    SUPPLY_REQUESTS_DETAIL_ROUTE,
  ],
  [ProgramModulesEnum.LIBRARY]: [LIBRARY_ROUTE],
  [ProgramModulesEnum.DOCTOR_BENEFITS]: [
    PROCEDURES_FIRST_MEDICINE_ROUTE,
    PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE,
    PROCEDURE_DETAIL_ROUTE,
    MEDICATION_RELEASE_ROUTE,
  ],
  [ProgramModulesEnum.TRAVEL]: [
    TRAVEL_REQUEST_ROUTE,
    TRAVEL_REQUESTS_ROUTE,
    TRAVEL_REQUESTS_DETAIL_ROUTE,
  ],
  [ProgramModulesEnum.PATIENTS]: [
    PATIENTS_ROUTE,
    PATIENT_DETAIL_ROUTE,
    MEDICATION_ADMINISTRATION,
  ],
  [ProgramModulesEnum.EDIT_USER]: [
    USER_DETAILS_ROUTE,
  ],
  [ProgramModulesEnum.CONTENTS]: [
    CONTENTS_ROUTE,
  ],
  [ProgramModulesEnum.DISCOUNTS]: [
    DISCOUNTS_ROUTE,
  ],

};

const getRoutes = (
  role: UserType,
  modules: ProgramModulesEnum[],
  userModules: ProgramModulesEnum[],
  bypass: boolean,
) => {
  const programOnlyModules = [
    ProgramModulesEnum.DOCTOR_BENEFITS,
    ProgramModulesEnum.LIBRARY,
    ProgramModulesEnum.SALESFORCE_VISIT,
  ];

  const rts = roleRoutes[role];
  let mrts = [...moduleRoutes.base];
  modules
    .filter((m) => bypass || programOnlyModules.includes(m) || userModules.includes(m))
    .forEach((m) => {
      mrts = [...mrts, ...(moduleRoutes[m] ? moduleRoutes[m] : [])];
    });
  return rts.filter((r) => mrts.includes(r)).map((r) => routes[r]);
};

export const RoutesContext = createContext<RoutesContextState>(defaultState);

export const RoutesProvider = ({ children }: RoutesContextProps): JSX.Element => {
  const [state, setState] = useState<RoutesContextState>(defaultState);
  const { role, modules: userModules, isSysadmin } = useAuth();
  const { modules, program } = useProgram();

  useEffect(() => {
    if (!role || !modules || !program) return;
    const r = getRoutes(role, modules || [], userModules[program.id], isSysadmin);
    if (!r || !r.length) return;
    setState({
      default: r[0].route,
      routes: r,
    });
  }, [role, modules, program]);

  return <RoutesContext.Provider value={state}>{children}</RoutesContext.Provider>;
};

export const useRoutes = (): RoutesContextState => useContext<RoutesContextState>(RoutesContext);
