import React from 'react';
import {
  getAddressByCEP,
  registerProcedure,
  getFollowupTravelGroup,
  getProgramFollowupTravels,
  getTravelGroupTreatmentLocations,
} from '@psp/common';
import TravelRequest, { TravelRequestProps } from '../../components/Travel/Request';

const dispatchers: Pick<
TravelRequestProps,
  'getAddressByCEP' | 'registerProcedure' | 'getFollowupTravelGroup' | 'getProgramFollowupTravels' | 'getTravelGroupTreatmentLocations'
> = {
  getAddressByCEP,
  registerProcedure,
  getFollowupTravelGroup,
  getProgramFollowupTravels,
  getTravelGroupTreatmentLocations,
};

export default function TravelRequestComposer(): JSX.Element {
  return <TravelRequest {...dispatchers} />;
}
