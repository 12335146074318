import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  GetInventoryAmount,
  GetProductPresentations,
  GetProgramProducts,
  RegisterProcedure,
  RegisterProcedureParams,
  ValidationError,
  extractValidationErrors,
  medicineReleaseForm,
  t,
  InventoryType,
  ProcedureType,
} from '@psp/common';
import { toast } from 'react-toastify';

import { useLoading } from '../../../contexts/loading.context';
import Input from '../../Input';
import CheckboxInput from '../../CheckboxInput';
import GenderSelect from '../../GenderSelect';
import DateInput from '../../DateInput';
import DocumentInput from '../../DocumentInput';
import Button from '../../Button';
import MaskedPhoneInput from '../../MaskedPhoneInput';
import ProductSelect, { ProductSelectValues } from '../../ProductSelect';

import { useStyles, useTooltipStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import Chip from '../../Chip';
import CrmInput from '../../CrmInput';
import StateSelect from '../../StateSelect';
import { useAuth } from '../../../contexts/auth.context';

export type MedicationReleaseProps = {
  getInventoryAmount: GetInventoryAmount;
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  registerProcedure: RegisterProcedure;
};

type MedicationReleaseState = {
  inventory?: number;
};

const defaultState: MedicationReleaseState = {
  inventory: undefined,
};

export default function MedicationRelease({
  getInventoryAmount,
  registerProcedure,
  ...dispatchers
}: MedicationReleaseProps): JSX.Element {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program, modulesMap } = useProgram();
  const { isDoctor } = useAuth();
  const [state, setState] = useState<MedicationReleaseState>(defaultState);
  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);

  const doctorBenefits: boolean = useMemo(
    () => modulesMap.doctorBenefits && !isDoctor, [modulesMap.doctorBenefits, isDoctor],
  );

  const handlePhoneFocus = () => {
    setShowPhoneTooltip(true);
  };

  const handlePhoneBlur = () => {
    setShowPhoneTooltip(false);
  };

  const handleProductChange = useCallback(
    (value: ProductSelectValues) => {
      if (!value || !value.productId || !value.presentationId) return;
      showLoading();
      setState({
        ...state,
        inventory: undefined,
      });
      (async () => {
        try {
          const inventory = await getInventoryAmount.execute({
            inventory: InventoryType.FIRST_MEDICINE,
            groupId: value.productId,
            itemId: value.presentationId,
          });
          setState({
            ...state,
            inventory,
          });
          formRef.current.setFieldValue('patient.acceptSms', true);
          formRef.current.setFieldValue('patient.acceptEmail', true);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [state],
  );

  const handleSubmit = useCallback(
    (data: any): void => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          const req = ((await medicineReleaseForm.validate(
            {
              ...data,
              type: ProcedureType.FIRST_MEDICINE,
              programId: program!.id,
            },
            {
              abortEarly: false,
            },
          )) as unknown) as RegisterProcedureParams;
          await registerProcedure.execute({
            ...req,
            groupId: data.productId,
            itemIds: [data.presentationId],
          });
          toast.success(t('msg.successToReleaseMedicine'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
          formRef.current.reset();
          setState(defaultState);
        } catch (err) {
          console.log(err);
          if (err.message === 'insufficientInventory') {
            toast.info(t('err.insufficientInventory'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'existingProcedure') {
            toast.info(t('err.existingPatientProcedure'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            let errors = {};
            if (err instanceof ValidationError) {
              errors = extractValidationErrors(err);
              formRef.current.setErrors(errors);
            } else if (err.name === 'BadRequestError') {
              toast.info(err.message, {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            } else {
              toast.info(t('err.failedToReleaseMedication'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [program],
  );

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <PaperTitle title={t('selectMedication')} />
        <PaperContent>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ patient: { acceptSms: true, acceptEmail: true } }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm={10} lg={6}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  className={classes.nowrap}
                >
                  <Grid item xs={12} sm={10}>
                    <ProductSelect
                      {...dispatchers}
                      formRef={formRef}
                      onSelectedValueChange={handleProductChange}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    {state.inventory !== undefined && (
                      <Chip label={`Estoque disponível: ${state.inventory}`} color="info" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {state.inventory !== undefined && state.inventory > 0 && (
                <Grid item>
                  <Grid container spacing={2} direction="column" className={classes.nowrap}>
                    <Grid item>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={12} sm={4} lg={3}>
                          <Input
                            name="patient.cpf"
                            variant="outlined"
                            label={t('cpf')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="off"
                            InputProps={{
                              inputComponent: DocumentInput as any,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3}>
                          <Input
                            name="patient.name"
                            variant="outlined"
                            label={t('name')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                          />
                        </Grid>
                        {!doctorBenefits && (
                          <>
                            <Grid item xs={12} sm={4} lg={3}>
                              <GenderSelect
                                name="patient.gender"
                                variant="outlined"
                                label={t('gender')}
                                displayEmpty
                                optional
                                className={classes.input}
                                disabled={isLoading}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <DateInput
                                name="patient.birthDate"
                                inputVariant="outlined"
                                label={t('birthDate')}
                                format="dd/MM/yyyy"
                                optional
                                className={classes.input}
                                disabled={isLoading}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="patient.email"
                                variant="outlined"
                                label={t('email')}
                                className={classes.input}
                                optional
                                disabled={isLoading}
                                autoComplete="nope"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} sm={4} lg={3}>
                          <Tooltip
                            open={showPhoneTooltip}
                            placement="top"
                            title={t('tooltips.patientCellphone')}
                            classes={tooltipClasses}
                          >
                            <Input
                              name="patient.phone"
                              variant="outlined"
                              label={t('cellphone')}
                              className={classes.input}
                              disabled={isLoading}
                              autoComplete="nope"
                              onFocus={handlePhoneFocus}
                              onBlur={handlePhoneBlur}
                              InputProps={{
                                inputComponent: MaskedPhoneInput as any,
                              }}
                            />
                          </Tooltip>
                        </Grid>
                        {doctorBenefits && (
                          <>
                            <Grid item xs={12} sm={4} lg={3}>
                              <Input
                                name="prescriberDoctor.crm"
                                variant="outlined"
                                label={t('crm')}
                                className={classes.input}
                                disabled={isLoading}
                                autoComplete="nope"
                                InputProps={{
                                  inputComponent: CrmInput as any,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3}>
                              <StateSelect
                                name="prescriberDoctor.uf"
                                variant="outlined"
                                label={t('uf')}
                                fullWidth
                                displayEmpty
                                className={classes.input}
                                disabled={isLoading}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} direction="row" alignItems="center">
                        <Grid item xs={12} sm>
                          <CheckboxInput
                            name="patient.acceptSms"
                            label={t('medication.release.acceptSms')}
                            disabled={isLoading}
                          />
                          {!doctorBenefits && (
                            <CheckboxInput
                              name="patient.acceptEmail"
                              label={t('medication.release.acceptEmail')}
                              disabled={isLoading}
                            />
                          )}
                        </Grid>
                        <Grid item>
                          <Button type="submit" variant="contained" color="primary">
                            Solicitar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
