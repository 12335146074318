import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core';
import { AlertOctagon as AlertIcon } from 'mdi-material-ui';
import { Form } from '@unform/web';
import {
  GetInventoryAmount,
  GetProductPatientProcedures,
  GetProgramProducts,
  GetPrograms,
  PatientProcedure,
  Product,
  Program,
  RegisterProcedure,
  RegisterProcedureParams,
  ValidationError,
  extractValidationErrors,
  patientProcedureRequestForm,
  t,
  InventoryType,
  ProcedureType,
  GetPatientFields,
  GetProcedureFields,
} from '@psp/common';
import { toast } from 'react-toastify';

import { useLoading } from '../../../contexts/loading.context';
import { MEDICATION_RELEASE_ROUTE, PATIENT_PROCEDURE_REQUEST_ROUTE } from '../../../constants';
import Select from '../../Select';
import Input from '../../Input';
import CheckboxInput from '../../CheckboxInput';
import PageTitle from '../../PageTitle';
import PageSubtitle from '../../PageSubtitle';
import GenderSelect from '../../GenderSelect';
import DateInput from '../../DateInput';
import DocumentInput from '../../DocumentInput';
import Button from '../../Button';
import MaskedPhoneInput from '../../MaskedPhoneInput';
import PatientProcedureSelect, { PatientProcedureSelectValues } from '../../PatientProcedureSelect';

import { useStyles } from './styles';
import { useProgram } from '../../../contexts/program.context';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import Chip from '../../Chip';
import DynamicProcedreField from '../../DynamicProcedureField';

export type PatientProcedureRequestProps = {
  getInventoryAmount: GetInventoryAmount;
  getProgramProducts: GetProgramProducts;
  getProductPatientProcedures: GetProductPatientProcedures;
  registerProcedure: RegisterProcedure;
  getPatientFields: GetPatientFields;
  getProcedureFields: GetProcedureFields;
};

type PatientProcedureRequestState = {
  patientProcedure?: PatientProcedure;
  inventory?: number;
  patientFieldConfig?: any[];
  procedureFieldConfig?: any[];
};

const defaultState: PatientProcedureRequestState = {
  patientProcedure: undefined,
  inventory: undefined,
};

export default function PatientProcedureRequest({
  getInventoryAmount,
  registerProcedure,
  getPatientFields,
  getProcedureFields,
  ...dispatchers
}: PatientProcedureRequestProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();
  const [state, setState] = useState<PatientProcedureRequestState>(defaultState);

  const handleProductChange = useCallback(
    (value: PatientProcedureSelectValues) => {
      if (!value || !value.productId || !value.patientProcedureId) return;
      showLoading();
      setState({
        ...state,
        inventory: undefined,
      });
      (async () => {
        try {
          const patientFields = await getPatientFields.execute({
            groupId: value.patientProcedureId,
          });
          const procedureFields = await getProcedureFields.execute({
            groupId: value.patientProcedureId,
          });
          const inventory = await getInventoryAmount.execute({
            inventory: InventoryType.PATIENT_PROCEDURE,
            groupId: value.productId,
            itemId: value.patientProcedureId,
          });
          setState({
            ...state,
            inventory,
            patientProcedure: value.patientProcedure,
            patientFieldConfig: patientFields,
            procedureFieldConfig: procedureFields,
          });

          if (patientFields) {
            // buildRequiredField(patientFields);
          }
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    },
    [state],
  );

  const setErrorMensages = (data: any, extractedErrors: any = {}): boolean => {
    let isValid = true;
    const validationErrors = {} as any;

    state.patientFieldConfig?.forEach((field) => {
      if (
        field.visible
        && field.required
        && (data.patient[field.field] === undefined
          || data.patient[field.field] === null
          || data.patient[field.field] === ''
          || data.patient[field.field] === false)
      ) {
        validationErrors[`patient.${field.field}`] = t('validation.requiredField');
        isValid = false;
      }
    });

    state.procedureFieldConfig?.forEach((field) => {
      if (
        field.active
        && field.viewType === 'required'
        && (data.procedureFieldsAnswers[field.name] === undefined
          || data.procedureFieldsAnswers[field.name] === ''
          || data.procedureFieldsAnswers[field.name] === false)
      ) {
        validationErrors[`procedureFieldsAnswers.${field.name}`] = t('validation.requiredField');
        isValid = false;
      }

      if (
        field.min !== null
        && field.min !== ''
        && data.procedureFieldsAnswers[field.name] !== null
        && data.procedureFieldsAnswers[field.name].length < field.min
      ) {
        const length = field.min;
        validationErrors[`procedureFieldsAnswers.${field.name}`] = t('validation.minLength', {
          length,
        });
        isValid = false;
      }

      if (
        field.requiredAnswers !== null
        && field.requiredAnswers !== ''
        && data.procedureFieldsAnswers[field.name] !== null
        && data.procedureFieldsAnswers[field.name].toString() !== field.requiredAnswers
      ) {
        let answer = field.requiredAnswers;

        if (field.requiredAnswers === 'true') {
          answer = 'Sim';
        }
        if (field.requiredAnswers === 'false') {
          answer = 'Não';
        }

        validationErrors[`procedureFieldsAnswers.${field.name}`] = t(
          'validation.answersMustMatch',
          { answer },
        );
        isValid = false;
      }
    });

    formRef.current.setErrors({ ...validationErrors, ...extractedErrors });
    return isValid;
  };
  const handleSubmit = useCallback(
    (data: any): void => {
      if (!program) return;
      showLoading();
      (async () => {
        try {
          const req = ((await patientProcedureRequestForm.validate(
            {
              ...data,
              type: ProcedureType.PATIENT_PROCEDURE,
              programId: program!.id,
            },
            {
              abortEarly: false,
            },
          )) as unknown) as RegisterProcedureParams;
          if (setErrorMensages(data)) {
            await registerProcedure.execute({
              ...req,
              groupId: data.productId,
              itemIds: [data.patientProcedureId],
            });
            toast.success(t('msg.successToRequestPatientProcedure'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
            formRef.current.reset();
            setState(defaultState);
          }
        } catch (err) {
          console.log(err);
          if (err.message === 'insufficientInventory') {
            toast.info(t('err.insufficientInventory'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'existingProcedure') {
            toast.info(t('err.existingPatientProcedure'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'insufficientSupplies') {
            toast.info(t('err.insufficientSupplies'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            let errors = {};
            if (err instanceof ValidationError) {
              errors = extractValidationErrors(err);
              formRef.current.setErrors(errors);
            } else if (err.name === 'BadRequestError') {
              toast.info(err.message, {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            } else {
              toast.info(t('err.failedToRequestPatientProcedure'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [program, state],
  );

  const buildPersonField = (field: any): JSX.Element => {
    if (field.field === 'birthDate' && field.visible) {
      return (
        <>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <DateInput
              name="patient.birthDate"
              inputVariant="outlined"
              label="Data de Nascimento"
              format="dd/MM/yyyy"
              optional
              className={classes.input}
              disabled={isLoading}
            />
          </Grid>
        </>
      );
    }
    if (field.field === 'rg' && field.visible) {
      return (
        <>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <Input
              name="patient.rg"
              variant="outlined"
              label="RG"
              className={classes.input}
              disabled={isLoading}
              autoComplete="nope"
            />
          </Grid>
        </>
      );
    }
    if (field.field === 'cep' && field.visible) {
      return (
        <>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <Input
              name="patient.cep"
              variant="outlined"
              label="CEP"
              className={classes.input}
              disabled={isLoading}
              autoComplete="nope"
            />
          </Grid>
        </>
      );
    }
    if (field.field === 'initials' && field.visible) {
      return (
        <>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <Input
              name="patient.initials"
              variant="outlined"
              label="Iniciais"
              className={classes.input}
              disabled={isLoading}
              autoComplete="nope"
            />
          </Grid>
        </>
      );
    }
    if (field.field === 'caregiverName' && field.visible) {
      return (
        <>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <Input
              name="patient.caregiverName"
              variant="outlined"
              label="Nome do cuidador"
              className={classes.input}
              disabled={isLoading}
              autoComplete="nope"
            />
          </Grid>
        </>
      );
    }
    if (field.field === 'email' && field.visible) {
      return (
        <>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <Input
              name="patient.email"
              variant="outlined"
              label="Email de contato"
              className={classes.input}
              disabled={isLoading}
              autoComplete="nope"
            />
          </Grid>
        </>
      );
    }
    return <></>;
  };

  const buildPatientAcceptFields = useCallback(() => {
    if (!state.patientFieldConfig) return <></>;
    const fields = [];
    const fieldMap = state.patientFieldConfig
      .filter((x) => x.visible)
      .reduce((p, c) => ({ ...p, [c.field]: c }), {});
    if (fieldMap.acceptSms && fieldMap.acceptSms.visible) {
      fields.push(
        <CheckboxInput
          name="patient.acceptSms"
          label={t('pages.exam.request.acceptSms')}
          disabled={isLoading}
        />,
      );
    }
    if (fieldMap.acceptPhone && fieldMap.acceptPhone.visible) {
      fields.push(
        <CheckboxInput
          name="patient.acceptPhone"
          label={t('pages.exam.request.acceptPhone')}
          disabled={isLoading}
        />,
      );
    }
    if (fieldMap.acceptEmail && fieldMap.acceptEmail.visible) {
      fields.push(
        <CheckboxInput
          name="patient.acceptEmail"
          label={t('pages.exam.request.acceptEmail')}
          disabled={isLoading}
        />,
      );
    }

    return fields;
  }, [state.patientFieldConfig, isLoading]);

  const buildBottomProcedureFields = useCallback(() => {
    if (!state.procedureFieldConfig) return <></>;
    const fieldGroups = state.procedureFieldConfig
      .filter((x) => x.active && x.showAtBottom)
      .reduce((p, c) => ({
        ...p,
        [c.group ?? 0]: [...(p[c.group ?? 0] ?? []), c],
      }), {});

    const fields = Object.keys(fieldGroups)
      .sort((a, b) => a.localeCompare(b))
      .map((k) => (
        <Grid key={k} item xs={12} sm>
          {
          fieldGroups[k]
            .sort((a: any, b: any) => a.order - b.order)
            .map((x: any) => (
              <DynamicProcedreField
                key={x.id}
                name={`procedureFieldsAnswers.${x.name}`}
                max={x.max}
                mask={x.mask}
                label={x.name}
                fieldType={x.fieldType}
                options={x.options}
                active
              />
            ))
          }
        </Grid>
      ));

    return fields;
  }, [state.procedureFieldConfig, isLoading]);

  const buildProcedureField = (field: any): JSX.Element => {
    if (field.active && !field.showAtBottom) {
      return (
        <>
          <Grid key={field.id} item xs={12} sm={4} lg={3} xl={2}>
            <DynamicProcedreField
              name={`procedureFieldsAnswers.${field.name}`}
              max={field.max}
              mask={field.mask}
              label={field.name}
              fieldType={field.fieldType}
              options={field.options}
              active
            />
          </Grid>
        </>
      );
    }

    return <></>;
  };

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <PaperTitle title={t('selectPatientProcedure')} />
        <PaperContent>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            initialData={{ patient: { acceptSms: true, acceptEmail: true } }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm={10} lg={6}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  className={classes.nowrap}
                >
                  <Grid item xs={12} sm={10}>
                    <PatientProcedureSelect
                      {...dispatchers}
                      formRef={formRef}
                      onSelectedValueChange={handleProductChange}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    {state.inventory !== undefined
                      && !state.patientProcedure?.canRequestWithoutInventory && (
                        <Chip label={`Estoque disponível: ${state.inventory}`} color="info" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {state.inventory !== undefined
                && (state.inventory > 0 || state.patientProcedure?.canRequestWithoutInventory) && (
                  <Grid item>
                    <Grid container spacing={2} direction="column" className={classes.nowrap}>
                      <Grid item>
                        <Grid container spacing={2} direction="row">
                          <Grid item xs={12} sm={4} lg={2}>
                            <Input
                              name="patient.cpf"
                              variant="outlined"
                              label={t('patientCpf')}
                              className={classes.input}
                              disabled={isLoading}
                              autoComplete="off"
                              InputProps={{
                                inputComponent: DocumentInput as any,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} lg={2}>
                            <Input
                              name="patient.name"
                              variant="outlined"
                              label={t('name')}
                              className={classes.input}
                              disabled={isLoading}
                              autoComplete="nope"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} lg={2}>
                            <Input
                              name="patient.phone"
                              variant="outlined"
                              label={t('cellphone')}
                              className={classes.input}
                              disabled={isLoading}
                              autoComplete="nope"
                              InputProps={{
                                inputComponent: MaskedPhoneInput as any,
                              }}
                            />
                          </Grid>
                          {state.patientFieldConfig
                            && state.patientFieldConfig.map((x) => buildPersonField(x))}
                          {state.procedureFieldConfig
                            && state.procedureFieldConfig
                              .sort((a: any, b: any) => a.order - b.order)
                              .map((x) => buildProcedureField(x))}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={2} direction="row" alignItems="flex-end">
                          <Grid item xs={12} sm>
                            <Grid container direction="column" spacing={3} xs={12} sm>
                              {buildPatientAcceptFields()}
                              {buildBottomProcedureFields()}
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Button type="submit" variant="contained" color="primary">
                              Solicitar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
              )}
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
    </Box>
  );
}
