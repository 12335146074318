import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Container,
  Grid,
  Slide,
  Fade,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CheckAll } from 'mdi-material-ui';
import { Form } from '@unform/web';
import clsx from 'clsx';
import {
  t,
  GetProgramBySlug,
  Login,
  Program,
  ValidationError,
  extractValidationErrors,
  signinForm,
  LoginParams,
  ProgramModulesEnum,
} from '@psp/common';

import { useQuery } from '../../hooks/use-query';
import Input from '../Input';
import ProgramStyles from '../ProgramStyles';
import SigninFooter from '../SigninFooter';
import SigninNavbar from '../SigninNavbar';

import { useStyles } from './styles';
import { useProgram } from '../../contexts/program.context';

export type SigninProps = {
  getProgramBySlug: GetProgramBySlug;
  login: Login;
};

const slideTimeout = {
  enter: 500,
  exit: 400,
};
const fadeTimeout = {
  enter: 1000,
  exit: 200,
};

export default function Signin({ getProgramBySlug, login }: SigninProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const formRef = useRef({} as any);
  const { program = '' } = useQuery();
  const [loadedProgram, setLoadedProgram] = useState<Program | Record<string, never> | undefined>(
    undefined,
  );
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectProgram } = useProgram();

  const handleSubmit = useCallback(
    (data: any) => {
      setLoading(true);
      (async () => {
        try {
          const req = ((await signinForm.validate(data, {
            abortEarly: false,
          })) as unknown) as LoginParams;
          await login.execute({
            ...req,
            programId: loadedProgram && loadedProgram.id ? loadedProgram.id : '',
          });
          if (loadedProgram && loadedProgram.id) {
            selectProgram(loadedProgram as Program);
          }
          window.location.href = '/';
        } catch (err) {
          console.log(err);
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else if (err.message === 'userNotInProgram') {
            formRef.current.setErrors({
              username: t('err.userNotInProgram'),
            });
          } else {
            formRef.current.setErrors({
              username: t('err.invalidUser'),
            });
          }
        } finally {
          setLoading(false);
        }
      })();
    },
    [loadedProgram],
  );

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPasswordMouseDown = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (program) {
      (async () => {
        try {
          const res = await getProgramBySlug.execute({
            slug: program,
          });
          setLoadedProgram(res);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      setLoadedProgram({});
    }
  }, [program]);

  if (!loadedProgram) {
    return <></>;
  }

  return (
    <Grid container direction="column" className={clsx(classes.container, 'signin-container')}>
      <ProgramStyles program={loadedProgram} />
      <Grid item className={classes.navbarContainer}>
        <SigninNavbar />
      </Grid>
      <Grid item xs className={classes.main}>
        <Container className={classes.mainContainer}>
          <Grid
            container
            spacing={2}
            direction="column"
            justify="center"
            className={classes.gridContainer}
          >
            <Grid item sm={8} md={6}>
              <Slide in timeout={slideTimeout} direction="up">
                <Grid container>
                  <Fade in timeout={fadeTimeout}>
                    <Grid item xs>
                      <Grid container spacing={6} direction="column">
                        <Grid item xs>
                          <Typography variant="h2" color="textPrimary" className={classes.title}>
                            {t('pages.signin.title')}
                          </Typography>
                          <Typography variant="h1" className={classes.subtitle}>
                            {t('pages.signin.subtitle')}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <ul className={classes.list}>
                            <li className={classes.listItem}>
                              <CheckAll className={classes.inline} />
                              <span className={classes.inline}>
                                &nbsp;&nbsp;
                                {t('procedures')}
                              </span>
                            </li>
                            <li className={classes.listItem}>
                              <CheckAll className={classes.inline} />
                              <span className={classes.inline}>
                                &nbsp;&nbsp;
                                {t('medicalOrders')}
                              </span>
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h5" color="primary" className={classes.loginLabel}>
                            {t('pages.signin.login')}
                          </Typography>
                          <br />
                          <Form ref={formRef} onSubmit={handleSubmit} className="signin-form">
                            <Grid
                              container
                              spacing={2}
                              className={classes.box}
                              direction="row"
                              alignItems="center"
                            >
                              <Grid item lg={5} md={12} sm={12} xs={12}>
                                <Input
                                  name="username"
                                  variant="outlined"
                                  size="medium"
                                  className={classes.helperText}
                                  placeholder={t('email')}
                                  disabled={loading}
                                  FormHelperTextProps={{
                                    className: classes.helperText,
                                  }}
                                />
                              </Grid>
                              {!isMd && (
                                <Grid item>
                                  <Divider className={classes.verticalDivider} />
                                </Grid>
                              )}
                              <Grid item lg={4} md={12} sm={12} xs={12}>
                                <Input
                                  name="password"
                                  variant="outlined"
                                  size="medium"
                                  className={classes.helperText}
                                  placeholder={t('password')}
                                  type={showPassword ? 'text' : 'password'}
                                  disabled={loading}
                                  FormHelperTextProps={{ className: classes.helperText }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={handleShowPasswordClick}
                                          onMouseDown={handleShowPasswordMouseDown}
                                        >
                                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item lg={2} md={12} sm={12} xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  type="submit"
                                  disabled={loading}
                                  fullWidth
                                >
                                  {t('access')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                          <br />
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            component="a"
                            href="/password-reset"
                            className={classes.link}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {t('forgotPassword')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fade>
                </Grid>
              </Slide>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item className={classes.footerContainer}>
        <SigninFooter program={loadedProgram} />
      </Grid>
    </Grid>
  );
}
