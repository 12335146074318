import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { t } from '@psp/common';

import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type StateSelectProps = Omit<SelectProps, 'children'>;

export default function StateSelect({ className, ...props }: StateSelectProps): JSX.Element {
  const classes = useStyles();
  return (
    <Select className={clsx(className, classes.formControl)} {...props}>
      <MenuItem value="" disabled />
      <MenuItem value="MALE">{t('male')}</MenuItem>
      <MenuItem value="FEMALE">{t('female')}</MenuItem>
      <MenuItem value="NOT_INFORMED">{t('notInformed')}</MenuItem>
    </Select>
  );
}
