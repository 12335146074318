import React, { useCallback } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { AccessPhasesFromPatientCare, t } from '@psp/common';

import { useHistory } from 'react-router-dom';
import TextItem from '../TextItem';

import { useStyles } from './styles';
import PaperContent from '../PaperContent';
import PatientAccessPhase, { StepperProps } from '../PatientAccessPhase';
import Button from '../Button';
import { MEDICATION_ADMINISTRATION } from '../../constants';

export type PatientHealthInfoProps = {
  data: AccessPhasesFromPatientCare[];
  patientId: string;
};

export default function PatientHealthInfo({
  data, patientId }: PatientHealthInfoProps): JSX.Element {
  const classes = useStyles();
  const { push } = useHistory();

  const handleNavigateToMedicationAdminPageFiltered = useCallback(
    () => {
      push(MEDICATION_ADMINISTRATION.replace(':id', patientId));
    },
    [],
  );

  function convertAccessPhases(accessPhases: AccessPhasesFromPatientCare[]): StepperProps[] {
    return accessPhases.map((phase) => ({
      id: phase.idLocalAccessHistoryStatus,
      description: phase.status,
      date: phase.date,
      status: 'COMPLETED',
    }));
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.container}>
        <PaperContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <strong>{t('patientData')}</strong>
            </Grid>
            <Grid item>
              <TextItem label="Via de acesso" content={data[0]?.accessWay ? data[0]?.accessWay : '-'} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data.length !== 0 && <PatientAccessPhase data={convertAccessPhases(data)} />}
            <Grid item>
              <Button onClick={handleNavigateToMedicationAdminPageFiltered} variant="contained" color="primary">{t('trackApplication')}</Button>
            </Grid>
          </Grid>
        </PaperContent>
      </Paper>
    </Grid>
  );
}
