import React from 'react';
import {
  getInventoryAmount,
  getProgramProducts,
  getProductPresentations,
  registerProcedure,
} from '@psp/common';
import MedicationRelease, { MedicationReleaseProps } from '../../components/Medication/Release';

const dispatchers: Pick<
  MedicationReleaseProps,
  'getInventoryAmount' | 'getProgramProducts' | 'getProductPresentations' | 'registerProcedure'
> = {
  getInventoryAmount,
  getProgramProducts,
  getProductPresentations,
  registerProcedure,
};

export default function MedicationReleaseComposer(): JSX.Element {
  return <MedicationRelease {...dispatchers} />;
}
