import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { t, GetProductPresentations, GetProgramProducts, Presentation, Product } from '@psp/common';

import { useLoading } from '../../contexts/loading.context';
import Select from '../Select';
import { useProgram } from '../../contexts/program.context';

export type ProductSelectValues = {
  productId: string;
  presentationId: string;
};

export type ProductSelectProps = {
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  onSelectedValueChange: (values: ProductSelectValues) => void;
  optionalProduct?: boolean;
  optionalPresentation?: boolean;
  formRef: any;
  direction?: 'column' | 'row';
};

type ProductSelectState = {
  products: Product[];
  productId: string;
  presentations: Presentation[];
  presentationId: string;
};

const defaultState: ProductSelectState = {
  products: [],
  productId: '',
  presentations: [],
  presentationId: '',
};

export default function ProductSelect({
  getProgramProducts,
  getProductPresentations,
  onSelectedValueChange,
  optionalProduct = false,
  optionalPresentation = false,
  formRef,
  direction = 'row',
}: ProductSelectProps): JSX.Element {
  const productRef: any = useRef(null);
  const presentationRef: any = useRef(null);
  const [state, setState] = useState<ProductSelectState>(defaultState);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { program } = useProgram();

  useEffect(
    useCallback(() => {
      if (!program) return;
      showLoading();
      setState({
        ...defaultState,
      });
      (async () => {
        try {
          const products = await getProgramProducts.execute({ programId: program?.id });
          setState({
            ...defaultState,
            products,
          });
          onSelectedValueChange({
            productId: '',
            presentationId: '',
          });
          formRef.current.setFieldValue('productId', null);
          formRef.current.setFieldValue('presentationId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    }, [state, onSelectedValueChange]),
    [program],
  );

  const handleProductChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
        presentations: [],
      });
      (async () => {
        try {
          const presentations = await getProductPresentations.execute({ productId: value });
          setState({
            ...state,
            productId: value,
            presentations,
          });
          onSelectedValueChange({
            productId: value,
            presentationId: '',
          });
          formRef.current.setFieldValue('presentationId', null);
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          productRef.current.focus();
        }
      })();
    },
    [state, onSelectedValueChange],
  );

  const handlePresentationChange = useCallback(
    (value) => {
      if (!value) return;
      showLoading();
      setState({
        ...state,
      });
      (async () => {
        try {
          setState({
            ...state,
            presentationId: value,
          });
          onSelectedValueChange({
            productId: state.productId,
            presentationId: value,
          });
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
          presentationRef.current.focus();
        }
      })();
    },
    [state, onSelectedValueChange],
  );
  return (
    <Grid container spacing={2} direction={direction}>
      <Grid item xs={12} md={direction === 'column' ? 12 : 6}>
        <Select
          label={t('product')}
          variant="outlined"
          name="productId"
          fullWidth
          onSelectedValueChange={handleProductChange}
          disabled={state.products.length === 0 || isLoading}
          inputRef={productRef}
          optional={optionalProduct}
        >
          {state.products.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={direction === 'column' ? 12 : 6}>
        <Select
          label={t('presentation')}
          variant="outlined"
          name="presentationId"
          fullWidth
          onSelectedValueChange={handlePresentationChange}
          disabled={state.presentations.length === 0 || isLoading}
          inputRef={presentationRef}
          optional={optionalPresentation}
        >
          {state.presentations.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
